.unit-feedbacks-v1 {
	.ptitle {
		display: inline-block;
	}

	.ratings {
		display: flex;
		flex-wrap: wrap;

		.rating {
			margin: 0 30px 15px 0;

			.fa {
				color: var(--color-primary);
				background-image: unset !important;
				height: auto;
				width: auto;
			}

			.fa-star:before {
				content: "\f005";
			}

			.fa-star-half-alt:before {
				content: "\f5c0";
			}

			.fa-star-o:before {
				content: "\f006";
			}

			.fa.fa-star-o {
				font-family: "FontAwesome";
				font-weight: 400;
			}
		}
	}

	.feedbacks {

		clear: left;
		padding-top: 15px;

		.feedback {
			border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 30px;

			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: var(--font-size-md);
			}

			.author {
				font-size: var(--font-size-sm);
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--color-red);
				font-size: var(--font-size-md);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}