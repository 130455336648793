.unit-pricelist-v1 {
	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				&.service-type {
					font-size: 18px;
					font-weight: 700;
				}

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}

	.rent-table {
		tbody {
			display: none;

			&:nth-child(1) {
				display: table-row-group;
			}
		}
	}
}