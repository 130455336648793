.regionen-v1 {
	margin-top: var(--page-margin-top);

	.col-sm-6 {
		@media (max-width: 767px) {
			-webkit-box-flex: 0;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.image_container {
		.img-responsive {
			width: 100%;
			margin-bottom: 15px;
			height: 400px;
			object-fit: cover;

			@media (max-width: 767px) {
				height: auto;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				height: 240px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				height: 330px;
			}
		}
	}
}