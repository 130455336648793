.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media (max-height: 600px) {
		min-height: 680px;
	}

	@media (max-width: 767px) {
		height: auto;
		min-height: unset;
		margin-top: 125px;
	}



	.owl-carousel {
		z-index: unset;
	}

	.banner-label {
		max-width: 650px;
		margin: 0 auto;

		.banner-text {
			color: #fff;
			font-size: 20px;
			background-color: var(--color-primary);
			border-radius: 5px;
			padding: 15px;
			font-weight: bold;

		}
	}

	.banner-description {



		p {
			font-size: 24px;
			color: #fff;
			margin: 10px 0 0 0;
			text-shadow: 0 0.1rem 1rem rgb(0 0 0 / 50%);
			text-align: center;

			@media (max-width: 767px) {
				font-size: 18px;
				line-height: normal;
				color: var(--font-color-main);
				text-shadow: none;
			}

			@media (max-width: 374px) {
				font-size: 16px;
				line-height: normal;
			}
		}
	}

	/*.banner-description {
		position: absolute;
		top: 75vh;
		left: 0;
		right: 0;
		max-width: 1370px;
		width: 100%;
		margin: 0 auto;

		@media (max-width: 767px) {
			position: static;
		}


		p {
			font-size: 24px;
			color: #fff;
			margin: 10px 0 0 0;
			text-shadow: 0 0.1rem 1rem rgb(0 0 0 / 50%);
			text-align: center;

			@media (max-width: 767px) {
				font-size: 18px;
				line-height: normal;
				color: var(--font-color-main);
				text-shadow: none;
			}

			@media (max-width: 374px) {
				font-size: 16px;
				line-height: normal;
			}
		}
	}*/



	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		@media (max-width: 767px) {
			height: 400px;
		}

		@media (max-width: 574px) {
			height: 320px;
		}

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;

			@media (max-width: 767px) {
				height: 400px;
				min-height: 400px;
			}

			@media (max-width: 574px) {
				height: 320px;
				min-height: 320px;
			}
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);

			@media (max-height: 600px) {
				min-height: 680px;
			}

			@media (max-width: 767px) {
				height: 400px;
				min-height: 400px;
			}

			@media (max-width: 574px) {
				height: 320px;
				min-height: 320px;
			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 50vh;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-height: 600px) {
			top: 240px;
		}

		@media (max-width: 767px) {
			position: static;
		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
				font-size: 4vw;
				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 55px;
				}

				@media (max-width: 1140px) {
					font-size: 48px;
				}

				@media (max-width: 992px) {
					font-size: 2.8rem;
					line-height: 3rem;
				}

				@media (max-width: 767px) {
					font-size: 2.5rem;
					line-height: 2.8rem;
					text-shadow: none;
					color: var(--color-secondary);
					margin: 30px 0 10px 0;
				}

				@media (max-height: 600px) {

					font-size: 28px;
					line-height: normal;


				}

			}



			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}

	}
}