.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}


	.guest-reviews-sideabar {
		.stars {
			margin: 5px 0 0 0;

			.fa {
				color: var(--color-primary);
				background-image: unset !important;
				height: auto;
				width: auto;
			}

			.fa-star:before {
				content: "\f005";
			}

			.fa-star-half-alt:before {
				content: "\f5c0";
			}

			.fa-star-o:before {
				content: "\f006";
			}

			.fa.fa-star-o {
				font-family: "FontAwesome";
				font-weight: 400;
			}
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
		display: flex;

		i.fa {
			height: 24px;
			width: 7px;
			display: inline-block;
			margin-right: 5px;

			&:before {
				content: "";
			}

			&.fa-star {
				background-image: url(RESOURCE/img/icon-house.png);
			}

			&.fa-star-o {
				background-image: url(RESOURCE/img/icon-house-grey.png);
			}
		}
	}

}