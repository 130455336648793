.unit-image-gallery-v1 {
	margin-right: -5px;
	margin-left: -5px;
	margin-top: -5px;

	.visible-print {
		display: none !important;
	}

	.unitGallery {
		a {
			display: none;
			padding: 5px;
			height: 150px;

			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 100%;
				float: right;
				display: block;
				height: 540px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 400px;
				}
			}

			&:nth-child(2) {
				clear: both;
				margin-left: 201px;

				@media (max-width: 1499px) {
					margin-left: 165px;
				}

				@media (max-width: 1199px) {
					margin-left: 123px;
				}

				@media (max-width: 767px) {
					margin-left: 261px;
					clear: none;
					height: 140px;
				}

				@media (max-width: 480px) {
					margin-left: 180px;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				display: block;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(5) {
				position: relative;

				&:before {
					content: "+ mehr";
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
				}

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}

			&:nth-child(4) {
				@media (max-width: 767px) {
					position: relative;

					&:before {
						content: "+ mehr";
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						bottom: 0;
						text-align: center;
						width: 100%;
						color: #fff;
						font-weight: 600;
						font-size: var(--font-size-md);
						margin-top: -10px;
						z-index: 1;
						padding: 0 10px;
					}

					&:after {
						content: "";
						position: absolute;
						left: 5px;
						right: 5px;
						top: 5px;
						bottom: 5px;
						background: rgba(0, 0, 0, .5);
						z-index: 0;

						@media (max-width: 767px) {
							top: 5px;
							bottom: 5px;
						}
					}
				}

			}

			&:nth-child(5) {
				@media (max-width: 767px) {
					display: none;
				}
			}


		}

		.video-gallery {
			position: absolute;
			bottom: -1px;
			height: 153px;
			width: 200px;

			@media (max-width: 1499px) {
				width: 200px;
			}

			@media (max-width: 1199px) {
				width: 125px;
			}

			@media (max-width: 767px) {

				bottom: 141px;
				height: 139px;
				width: 260px;
				left: 10px;
			}

			@media (max-width: 480px) {

				width: 180px;

			}

			.unit-videos {
				height: 100%;

				iframe {
					height: 100%;
					pointer-events: none;
				}
			}
		}
	}

}

#unitVideo {
	.close {
		position: absolute;
		top: 5px;
		right: 20px;
		z-index: 9;
	}

	.modal-body {
		padding: 2rem 1rem 1rem 1rem;
	}
}