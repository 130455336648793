.leistungen-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/leistungen-banner-tab-2.jpg);
		height: 440px;

		@media (max-width: 1199px) {
			background-image: url(RESOURCE/img/leistungen-banner-tab.jpg);
		}

		@media (max-width: 992px) {
			background-image: url(RESOURCE/img/leistungen-banner-tab.jpg);
			height: 320px;
		}

		@media (max-width: 767px) {
			background-image: url(RESOURCE/img/leistungen-banner-mob-1.jpg);
			height: 220px;
		}

		@media (max-width: 500px) {
			background-image: url(RESOURCE/img/leistungen-banner-mob.jpg);
		}
	}

	.pdf-link {
		position: relative;
		padding-left: 25px;

		img {
			position: absolute;
			left: 0;
			top: 2px;
		}
	}

	.pdf-icon {
		width: 18px;
	}
}