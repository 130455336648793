.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-book-now-v1 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 270px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}
}

.vo-unit-body {
	.popover {
		.popover-body {
			ul {
				height: auto;
				overflow: inherit;
			}
		}
	}
}